import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HeroPage from './Components/HeroPage';
import HowItWorks from './Components/HowItWorks';
import MLKits from './Components/MLKits';
import Benefits from './Components/Benefits';
import Flowchart from './Components/Flowchart';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import CaseStudies from './Components/CaseStudies';
import Support from './Components/Support';
import WhyStatLeap from './Components/WhyStatLeap';
import BundlePurchase from './Components/BundlePurchase';
import Profile from './Components/Profile';
import Login from './Components/Login';
import ProtectedRoute from './Components/ProtectedRoute';
import AdminPage from './Components/AdminPage';
import ProtectedAdminRoute from './Components/ProtectedAdminRoute';
import ProtectedInstructorRoute from './Components/ProtectedInstructorRoute';
import UserManagement from './Components/UserManagement';
import ToolkitManagement from './Components/ToolkitManagement';
import CaseStudyManagement from './Components/CaseStudyManagement';
import AdminInstructorRequests from './Components/AdminInstructorRequests';
import InstructorDashboard from './Components/InstructorDashboard';
import Resources from './Components/Resources';
import AboutUs from './Components/AboutUs'; 
import EULA from './Components/EULA'; 
import ScrollToTop from './Components/ScrollToTop';
import BundleSuccess from './Components/BundleSuccess';

function App() {
    const [mlKitsKey, setMLKitsKey] = useState(0);

    const handleMLKitsClick = () => {
        setMLKitsKey(prevKey => prevKey + 1); // Change the key to force re-render
    };

    return (
        <Router>
            <ScrollToTop />  {/* Scroll to top when route changes */}
            <div className="App">
                <Navbar onMLKitsClick={handleMLKitsClick} />
                <div className="content-wrapper">
                    <Routes>
                        <Route path="/" element={<HeroPage />} />
                        <Route path="/how-it-works" element={<HowItWorks />} />
                        <Route path="/ml-tools" element={<ProtectedRoute><MLKits key={mlKitsKey} /></ProtectedRoute>} />
                        <Route path="/benefits" element={<Benefits />} />
                        <Route path="/flowchart" element={<Flowchart />} />
                        <Route path="/purchase-bundle" element={<ProtectedRoute><BundlePurchase /></ProtectedRoute>} />
                        <Route path="/case-studies" element={<ProtectedRoute><CaseStudies /></ProtectedRoute>} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/why-stat-leap" element={<WhyStatLeap />} />
                        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/admin" element={<ProtectedAdminRoute><AdminPage /></ProtectedAdminRoute>} />
                        <Route path="/admin/user-management" element={<ProtectedAdminRoute><UserManagement /></ProtectedAdminRoute>} />
                        <Route path="/admin/toolkit-management" element={<ProtectedAdminRoute><ToolkitManagement /></ProtectedAdminRoute>} />
                        <Route path="/admin/case-study-management" element={<ProtectedAdminRoute><CaseStudyManagement /></ProtectedAdminRoute>} />
                        <Route path="/admin/instructor-requests" element={<ProtectedAdminRoute><AdminInstructorRequests /></ProtectedAdminRoute>} />
                        <Route path="/instructor-dashboard" element={<ProtectedInstructorRoute><InstructorDashboard /></ProtectedInstructorRoute>} />
                        <Route path="/resources" element={<Resources />} />
                        <Route path="/about" element={<AboutUs />} /> 
                        <Route path="/eula" element={<EULA />} /> 
                        <Route path="/success" element={<BundleSuccess />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
