import React from 'react';

const AboutUs = () => {
    return (
        <div className="mt-[90px] max-w-[1240px] mx-auto px-4 py-16">
            <h1 className="text-center text-4xl font-bold mb-4 text-[#25d366]">About Us</h1>
            <p className="text-center text-lg text-gray-600 mb-12">
                StatLeap is an innovative platform designed to make data analytics education accessible and effective for both students and educators. Tailored to support academic institutions, StatLeap provides a robust suite of tools for teaching data analytics, from beginner to advanced levels.
            </p>
            <p className="text-center text-lg text-gray-600 mb-12">
                Our platform includes curated learning materials, real-world case studies, and machine learning tools that enable hands-on learning. With a focus on scalability, flexibility, and ease of use, StatLeap empowers instructors to deliver dynamic data science courses while enhancing student engagement through interactive learning experiences.
            </p>
        </div>
    );
};

export default AboutUs;
