import React, { useState } from "react";
import { AiOutlineClose, AiOutlineExpandAlt } from 'react-icons/ai';
import { motion } from 'framer-motion';

const staggerContainer = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        },
    },
};

const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const benefitsData = [
    {
        title: "Instructor-Led Data Analytics",
        description: "Empowers faculty to teach data analytics through an engaging, interactive platform tailored for efficiency and depth.",
        moreContent: "StatLeap allows for easy course integration and customizable learning modules, making it ideal for faculty of all expertise levels.",
        borderColor: "border-indigo-500",
        textColor: "text-indigo-500",
    },
    {
        title: "Collaboration with Top Institutions",
        description: "Partnering with prestigious business schools to provide cutting-edge, real-world analytics education to students worldwide.",
        moreContent: "StatLeap ensures that learners get hands-on experience with industry-leading tools and frameworks.",
        borderColor: "border-purple-500",
        textColor: "text-purple-500"
    },
    {
        title: "Flexible Learning Modes",
        description: "Designed for seamless online and offline use, ensuring uninterrupted access to tools, no matter the learning environment.",
        moreContent: "With StatLeap, students can continue learning regardless of their location or internet connection.",
        borderColor: "border-blue-400",
        textColor: "text-blue-400"
    },
    {
        title: "Scalable for Any Class Size",
        description: "From small groups to large lectures, StatLeap ensures top-tier performance and accessibility across all class sizes.",
        moreContent: "The platform can handle the demands of large-scale classes without sacrificing speed or user experience.",
        borderColor: "border-yellow-400",
        textColor: "text-yellow-400"
    },
    {
        title: "Cost-Effective Subscription Model",
        description: "Affordable subscription plans grant unlimited access, balancing quality and value for students and institutions alike.",
        moreContent: "StatLeap's flexible pricing models ensure that institutions of any size can benefit from high-quality data tools.",
        borderColor: "border-green-500",
        textColor: "text-green-500"
    },
    {
        title: "No-Code Machine Learning Tools",
        description: "Powerful machine learning capabilities - without the need for coding - make data analysis approachable for all users.",
        moreContent: "StatLeap democratizes access to machine learning, giving all students the ability to work with advanced analytics.",
        borderColor: "border-red-500",
        textColor: "text-red-500"
    }
];

const Benefits = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="container relative flex flex-col justify-between h-full w-max-[1240px] px-10 mx-auto xl:px-0 mt-2">
            <p className="mb-12 text-lg text-center text-gray-500">Discover the key advantages of using StatLeap for your data analytics needs</p>
            <motion.div
                className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-10"
                variants={staggerContainer}
                initial="hidden"
                animate="show"
            >
                {benefitsData.map((benefit, index) => (
                    <motion.div
                        key={index}
                        className={`relative h-full p-5 bg-white border-2 ${benefit.borderColor} rounded-lg transform transition duration-500 
              ${expandedIndex === index ? `lg:col-span-1 lg:row-span-2 z-20` : "hover:scale-110"} 
              ${expandedIndex !== null && expandedIndex !== index ? "blur-sm" : ""}`}
                        onClick={() => handleExpand(index)}
                        style={{ cursor: 'pointer' }}
                        variants={fadeIn}
                    >
                        <span className={`absolute top-0 left-0 w-full h-full mt-1 ml-1 ${benefit.bgColor} rounded-lg`}></span>
                        <div className="relative h-full">
                            <div className="flex items-center justify-between -mt-1">
                                <h3 className="my-2 text-lg font-bold text-gray-800">{benefit.title}</h3>
                                <div
                                    className="bg-gray-200 p-2 rounded flex items-center justify-center"
                                    onClick={(e) => { e.stopPropagation(); handleExpand(index); }}
                                >
                                    {expandedIndex === index ? <AiOutlineClose size={20} /> : <AiOutlineExpandAlt size={20} />}
                                </div>
                            </div>
                            <p className={`mt-2 mb-1 text-xs font-medium ${benefit.textColor} uppercase`}>------------</p>
                            <p className="mb-2 text-gray-600">{benefit.description}</p>
                            {expandedIndex === index && <p className="mt-2 text-gray-600">{benefit.moreContent}</p>}
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default Benefits;
