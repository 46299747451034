import React, { useState, useMemo, useRef } from 'react';
import useFetchUsers from '../hooks/useFetchUsers'; // Fetches users for the "Role View"
import useFetchBundles from '../hooks/useFetchBundles'; // Fetches all bundles
import './UserManagement.css';
import UserDetail from './UserDetail';
import { exportToCsv } from '../utils/exportToCsv'; // Utility for CSV export

const UserManagement = ({ currentUser }) => {
    const { users, loading: usersLoading, error: usersError } = useFetchUsers(); // Fetching users
    const { bundles, loading: bundlesLoading, error: bundlesError } = useFetchBundles(); // Fetching bundles

    const [selectedUser, setSelectedUser] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [filterConfig, setFilterConfig] = useState({ email: '', displayName: '', role: '' });

    // State for managing view
    const [viewMode, setViewMode] = useState('role'); // 'role' or 'bundle'

    // Bundle-related state
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [currentAccessUsers, setCurrentAccessUsers] = useState([]);
    const [expiredAccessUsers, setExpiredAccessUsers] = useState([]);

    const [adminPage, setAdminPage] = useState(1);
    const [instructorPage, setInstructorPage] = useState(1);
    const [userPage, setUserPage] = useState(1);

    const [adminsPerPage, setAdminsPerPage] = useState(10);
    const [instructorsPerPage, setInstructorsPerPage] = useState(10);
    const [usersPerPage, setUsersPerPage] = useState(10);

    const userDetailRef = useRef(null);

    // Function to filter users by the selected bundle and check expiration dates from their purchasedBundles array
    const filterUsersByBundle = (bundleId) => {
        if (!bundleId) return;

        const currentUsers = [];
        const expiredUsers = [];

        users.forEach((user) => {
            if (user.purchasedBundles) {
                user.purchasedBundles.forEach((bundle) => {
                    if (bundle.bundleId === bundleId) {
                        let expirationDate = new Date(bundle.expirationDate._seconds * 1000);

                        if (expirationDate > new Date()) {
                            currentUsers.push({ ...user, expirationDate: expirationDate }); // Current access
                        } else {
                            expiredUsers.push({ ...user, expirationDate: expirationDate }); // Expired access
                        }
                    }
                });
            }
        });

        setCurrentAccessUsers(currentUsers);
        setExpiredAccessUsers(expiredUsers);
    };

    // Filtering logic
    const filteredUsers = useMemo(() => {
        return users.filter((user) => {
            return (
                user.email.toLowerCase().includes(filterConfig.email.toLowerCase()) &&
                user.displayName.toLowerCase().includes(filterConfig.displayName.toLowerCase())
            );
        });
    }, [users, filterConfig]);

    // Sorting logic for Role View
    const sortedUsers = useMemo(() => {
        let sortableUsers = [...filteredUsers];
        if (sortConfig.key) {
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [filteredUsers, sortConfig]);

    const handleUsersPerPageChange = (setPerPage, setPage) => (e) => {
        setPerPage(parseInt(e.target.value, 10));
        setPage(1); // Reset to the first page
    };

    const getCurrentTableData = (users, currentPage, usersPerPage) => {
        const startIndex = (currentPage - 1) * usersPerPage;
        const endIndex = startIndex + usersPerPage;
        return users.slice(startIndex, endIndex);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleFilterChange = (key, value) => {
        setFilterConfig((prevConfig) => ({
            ...prevConfig,
            [key]: value,
        }));
    };

    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setTimeout(() => {
            userDetailRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Slight delay to ensure UserDetail component is rendered
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSelectedUser(null); // Optionally reset the selected user
    };

    // Updated CSV export function to include bundle-specific expiration date
    const handleExportCsv = (users, filename) => {
        const csvData = users.map((user) => ({
            email: user.email,
            displayName: user.displayName,
            role: user.role || 'N/A',
            expirationDate: user.expirationDate ? user.expirationDate.toISOString() : 'N/A',
        }));
        exportToCsv(filename, csvData);
    };

    const renderTable = (title, users, currentPage, setPage, usersPerPage, setUsersPerPage) => {
        const totalPages = Math.ceil(users.length / usersPerPage);

        return (
            <div className="user-role-section">
                <h3>{title}</h3>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by email"
                                    value={filterConfig.email}
                                    onChange={(e) => handleFilterChange('email', e.target.value)}
                                />
                                <button onClick={() => requestSort('email')}>Email</button>
                            </th>
                            <th>
                                <input
                                    type="text"
                                    placeholder="Filter by name"
                                    value={filterConfig.displayName}
                                    onChange={(e) => handleFilterChange('displayName', e.target.value)}
                                />
                                <button onClick={() => requestSort('displayName')}>Name</button>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCurrentTableData(users, currentPage, usersPerPage).map((user) => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.displayName}</td>
                                <td>
                                    <button onClick={() => handleViewDetails(user)}>View Details</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination-controls">
                    <label htmlFor={`usersPerPage-${title}`}>Users per page:</label>
                    <select
                        id={`usersPerPage-${title}`}
                        value={usersPerPage}
                        onChange={handleUsersPerPageChange(setUsersPerPage, setPage)}
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>

                    <div className="pagination-buttons">
                        <button
                            onClick={() => setPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => setPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="user-management">
            <h2 className="user-management-title">User Management</h2>

            {/* View Toggle */}
            <div className="view-toggle">
                <button onClick={() => setViewMode('role')} className={viewMode === 'role' ? 'active' : ''}>
                    Role View
                </button>
                <button onClick={() => setViewMode('bundle')} className={viewMode === 'bundle' ? 'active' : ''}>
                    Bundle View
                </button>
            </div>

            {viewMode === 'role' ? (
                <>
                    {/* Role View */}
                    {renderTable(
                        'Admins',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'admin'),
                            adminPage,
                            adminsPerPage
                        ),
                        adminPage,
                        setAdminPage,
                        adminsPerPage,
                        setAdminsPerPage
                    )}
                    {renderTable(
                        'Instructors',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'instructor'),
                            instructorPage,
                            instructorsPerPage
                        ),
                        instructorPage,
                        setInstructorPage,
                        instructorsPerPage
                    )}
                    {renderTable(
                        'Users',
                        getCurrentTableData(
                            sortedUsers.filter((user) => user.role === 'user'),
                            userPage,
                            usersPerPage
                        ),
                        userPage,
                        setUserPage,
                        usersPerPage,
                        setUsersPerPage
                    )}
                </>
            ) : (
                <>
                    {/* Bundle View */}
                    <div className="bundle-view">
                        <h3>Bundle View</h3>
                        <label htmlFor="bundleSelect">Select Bundle:</label>
                        <select
                            id="bundleSelect"
                            value={selectedBundle}
                            onChange={(e) => {
                                setSelectedBundle(e.target.value);
                                filterUsersByBundle(e.target.value);
                            }}
                        >
                            <option value={null}>Select a Bundle</option>
                            {bundles.map((bundle) => (
                                <option key={bundle.id} value={bundle.id}>
                                    {bundle.name}
                                </option>
                            ))}
                        </select>

                        {currentAccessUsers.length > 0 || expiredAccessUsers.length > 0 ? (
                            <>
                                {/* Export CSV Button - Only in Bundle View */}
                                <div className="csv-export-buttons">
                                    <button
                                        className="export-button"
                                        onClick={() =>
                                            handleExportCsv(
                                                [...currentAccessUsers, ...expiredAccessUsers],
                                                `BundleView-${selectedBundle}.csv`
                                            )
                                        }
                                    >
                                        Export Bundle View as CSV
                                    </button>
                                </div>
                                <div className="bundle-users-section">

                                    {renderTable(
                                        'Current Access',
                                        currentAccessUsers,
                                        userPage,
                                        setUserPage,
                                        usersPerPage,
                                        setUsersPerPage
                                    )}


                                    {renderTable(
                                        'Expired Access',
                                        expiredAccessUsers,
                                        userPage,
                                        setUserPage,
                                        usersPerPage,
                                        setUsersPerPage
                                    )}
                                </div>

                            </>
                        ) : (
                            <div>No users found for this bundle</div>
                        )}
                    </div>
                </>
            )}

            {selectedUser && (
                <>
                    <div ref={userDetailRef}>
                        <UserDetail user={selectedUser} />
                    </div>
                    <button className="back-to-top" onClick={handleBackToTop}>
                        Back to Top
                    </button>
                </>
            )}
        </div>
    );
};

export default UserManagement;
