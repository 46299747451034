import React from 'react';
import { motion } from 'framer-motion';
import './ThreeStepDiagram.css';

const steps = [
    {
        title: 'Step 1: Sign Up',
        description: 'Register easily using your email. Preferably use an institutional account (e.g. .edu or other)',
        icon: 'fas fa-user-plus',
    },
    {
        title: 'Step 2: Choose a Plan or Enter Bundle Code',
        description: 'Select a subscription plan or use a code provided by your instructor or sent to you via email to unlock access.',
        icon: 'fas fa-key',
    },
    {
        title: 'Step 3: Access Your Tools',
        description: 'Immediately access powerful data analytics tools and resources after registering and selecting your plan or entering your bundle code.',
        icon: 'fas fa-box-open',
    }
];

const ThreeStepDiagram = () => {
    return (
        <div className="three-step-diagram-container">
            <h2 className="diagram-title">How to Get Started</h2>
            <div className="steps-grid">
                {steps.map((step, index) => (
                    <motion.div
                        key={index}
                        className="step-card"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.3 }}
                    >
                        <i className={`${step.icon} step-icon`}></i>
                        <h3 className="step-title">{step.title}</h3>
                        <p className="step-description">{step.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default ThreeStepDiagram;
