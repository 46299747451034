import React, { useState, useEffect } from 'react';
import useCalculateBundlePrice from '../hooks/useCalculateBundlePrice';
import useCreateBundle from '../hooks/useCreateBundle';
import useUpdateBundle from '../hooks/useUpdateBundle';
import './BundleForm.css';

const BundleForm = ({ caseStudies, mlKits, selectedBundle, userId, userRole, onReload }) => {
    const [bundleName, setBundleName] = useState('');
    const [bundleDescription, setBundleDescription] = useState('');
    const [period, setPeriod] = useState(1);
    const [durationInDays, setDurationInDays] = useState(null);
    const [useDays, setUseDays] = useState(false);
    const [selectedCaseStudies, setSelectedCaseStudies] = useState([]);
    const [selectedMlKits, setSelectedMlKits] = useState([]);
    const [overridePrice, setOverridePrice] = useState(0);
    const [useOverridePrice, setUseOverridePrice] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isBundleLoaded, setIsBundleLoaded] = useState(false);

    const { bundlePrice, isLoading: isPriceLoading } = useCalculateBundlePrice(
        selectedCaseStudies,
        selectedMlKits,
        caseStudies,
        mlKits,
        period
    );

    const isCreating = !selectedBundle;
    const isAdmin = userRole === 'admin';

    useEffect(() => {
        console.log('Initial caseStudies:', caseStudies);
        console.log('Initial mlKits:', mlKits);
        console.log('Selected bundle:', selectedBundle);
    }, []);

    useEffect(() => {
        if (caseStudies.length > 0 && mlKits.length > 0) {
            console.log('Data is fully loaded.');
            setIsDataLoaded(true);
        }
    }, [caseStudies, mlKits]);

    useEffect(() => {
        if (selectedBundle && isDataLoaded && !isBundleLoaded) {
            console.log('Loading selected bundle:', selectedBundle);
            setBundleName(selectedBundle.name || '');
            setBundleDescription(selectedBundle.description || '');
            setPeriod(selectedBundle.period || 1);
            setDurationInDays(selectedBundle.durationInDays || null);
            setUseDays(!!selectedBundle.durationInDays);
            setSelectedCaseStudies(selectedBundle.caseStudies || []);
            setSelectedMlKits(selectedBundle.mlKits || []);
            setUseOverridePrice(selectedBundle.bundlePrice !== parseFloat(bundlePrice));
            setOverridePrice(selectedBundle.bundlePrice || 0);
            setIsBundleLoaded(true); // Prevent re-loading after user interaction
        }
    }, [selectedBundle, isDataLoaded, bundlePrice, isBundleLoaded]);

    const { createBundle, loading: createLoading, success: createSuccess, error: createError } = useCreateBundle();
    const { updateBundle, loading: updateLoading, success: updateSuccess, error: updateError } = useUpdateBundle();

    useEffect(() => {
        if (createSuccess || updateSuccess) {
            console.log('Bundle created/updated successfully.');
            onReload();
            resetForm();
        }
    }, [createSuccess, updateSuccess, onReload]);

    const resetForm = () => {
        console.log('Resetting form...');
        setBundleName('');
        setBundleDescription('');
        setPeriod(1);
        setDurationInDays(null);
        setUseDays(false);
        setSelectedCaseStudies([]);
        setSelectedMlKits([]);
        setUseOverridePrice(false);
        setOverridePrice(0);
        setIsBundleLoaded(false); // Reset the bundle loaded flag
    };

    const handleSave = () => {
        // Use the override price if it's checked, otherwise use the calculated bundle price
        const finalPrice = useOverridePrice ? overridePrice : bundlePrice;

        // Construct the bundle data to send to the hooks
        const bundleData = {
            name: bundleName,
            description: bundleDescription,
            caseStudies: selectedCaseStudies, // Updated list of selected case studies
            mlKits: selectedMlKits, // Updated list of selected ML kits
            period: !useDays ? period : null,
            durationInDays: useDays ? durationInDays : null,
            price: bundlePrice, // Always send the calculated price (for reference)
            userId,
        };

        // If override price is used, attach it as manualPrice in the bundle data
        if (useOverridePrice) {
            bundleData.manualPrice = overridePrice;
        }

        // Call the appropriate hook based on whether we're creating or updating a bundle
        if (isCreating) {
            console.log('Creating new bundle with data:', bundleData);
            createBundle(bundleData); // Send bundleData with manualPrice if applicable
        } else {
            console.log('Updating existing bundle with data:', bundleData);
            updateBundle(selectedBundle.id, bundleData); // Send bundleData with manualPrice if applicable
        }
    };


    const handleCancelEdit = () => {
        console.log('Cancelling edit, resetting form.');
        resetForm();
        onReload();
    };

    const handleCaseStudySelection = (id) => {
        console.log('Selecting/deselecting case study:', id);
        setSelectedCaseStudies((prev) =>
            prev.includes(id) ? prev.filter((studyId) => studyId !== id) : [...prev, id]
        );
    };

    const handleMlKitSelection = (id) => {
        console.log('Selecting/deselecting ML kit:', id);
        setSelectedMlKits((prev) =>
            prev.includes(id) ? prev.filter((kitId) => kitId !== id) : [...prev, id]
        );
    };

    useEffect(() => {
        console.log('Price loading state:', isPriceLoading);
    }, [isPriceLoading]);

    if (!isDataLoaded) {
        console.log('Data is still loading...');
        return <div>Loading...</div>;
    }

    return (
        <div className="bundle-form">
            <h2>{isCreating ? 'Create a New Bundle' : 'Edit Bundle'}</h2>
            <input
                type="text"
                placeholder="Bundle Name"
                value={bundleName}
                onChange={(e) => {
                    console.log('Bundle name changed:', e.target.value);
                    setBundleName(e.target.value);
                }}
            />
            <textarea
                placeholder="Bundle Description"
                value={bundleDescription}
                onChange={(e) => {
                    console.log('Bundle description changed:', e.target.value);
                    setBundleDescription(e.target.value);
                }}
            />

            {(isCreating || isAdmin) && (
                <>
                    <label>
                        Toggle Duration Unit:
                        <input
                            type="checkbox"
                            checked={useDays}
                            onChange={(e) => {
                                console.log('Toggle duration unit to:', e.target.checked ? 'Days' : 'Months');
                                setUseDays(e.target.checked);
                            }}
                        />
                        {useDays ? 'Use Days' : 'Use Months'}
                    </label>

                    {!useDays && (
                        <label>
                            Access Period (Months):
                            <input
                                type="number"
                                min="1"
                                value={period}
                                onChange={(e) => {
                                    console.log('Access period changed to (months):', e.target.value);
                                    setPeriod(parseInt(e.target.value, 10) || 1);
                                }}
                            />
                        </label>
                    )}

                    {useDays && (
                        <label>
                            Access Duration (Days):
                            <input
                                type="number"
                                min="1"
                                value={durationInDays || ''}
                                onChange={(e) => {
                                    console.log('Access duration changed to (days):', e.target.value);
                                    setDurationInDays(parseInt(e.target.value, 10) || 1);
                                }}
                            />
                        </label>
                    )}

                    {/* Case Studies Section */}
                    <h3>Select Case Studies</h3>
                    <div className="case-studies-list">
                        {caseStudies.filter((study) => !study.isPrivate).map((study) => (
                            <div key={study.id}>
                                {study.name}
                                <input
                                    type="checkbox"
                                    checked={selectedCaseStudies.includes(study.id)}
                                    onChange={() => handleCaseStudySelection(study.id)}
                                />
                            </div>
                        ))}
                    </div>

                    {/* ML Toolkits Section */}
                    <h3>Select ML Toolkits</h3>
                    <div className="mlkits-list">
                        {mlKits.filter((kit) => !kit.isPrivate).map((kit) => (
                            <div key={kit.id}>
                                {kit.toolName}
                                <input
                                    type="checkbox"
                                    checked={selectedMlKits.includes(kit.id)}
                                    onChange={() => handleMlKitSelection(kit.id)}
                                />
                            </div>
                        ))}
                    </div>

                    {isAdmin && (
                        <>
                            <label>
                                Use Override Price:
                                <input
                                    type="checkbox"
                                    checked={useOverridePrice}
                                    onChange={(e) => {
                                        console.log('Override price toggled:', e.target.checked);
                                        setUseOverridePrice(e.target.checked);
                                    }}
                                />
                            </label>

                            {useOverridePrice && (
                                <label>
                                    Set Override Price:
                                    <input
                                        type="number"
                                        value={overridePrice}
                                        onChange={(e) => {
                                            console.log('Override price changed to:', e.target.value);
                                            setOverridePrice(parseFloat(e.target.value));
                                        }}
                                        step="0.01"
                                        min="0"
                                    />
                                </label>
                            )}
                        </>
                    )}

                    <h3>
                        Total Bundle Price: {useOverridePrice ? `$${overridePrice}` : isPriceLoading ? 'Calculating...' : `$${bundlePrice}`}
                    </h3>
                </>
            )}

            <button
                onClick={handleSave}
                disabled={
                    createLoading ||
                    updateLoading ||
                    !bundleName ||
                    !bundleDescription
                }
            >
                {isCreating
                    ? createLoading
                        ? 'Creating Bundle...'
                        : 'Create Bundle'
                    : updateLoading
                        ? 'Updating Bundle...'
                        : 'Update Bundle'}
            </button>

            {!isCreating && (
                <button onClick={handleCancelEdit} disabled={createLoading || updateLoading}>
                    Cancel Edit
                </button>
            )}

            {createError && (
                <div className="error">Error creating bundle: {createError.message}</div>
            )}
            {updateError && (
                <div className="error">Error updating bundle: {updateError.message}</div>
            )}
        </div>
    );
};

export default BundleForm;
