import React, { useState, useEffect } from 'react';
import useFetchCaseStudies from '../hooks/useFetchCaseStudies';
import useFetchMlKits from '../hooks/useFetchMlKits';
import useCreateBundle from '../hooks/useCreateBundle';
import useUpdateBundle from '../hooks/useUpdateBundle';
import useFetchUserBundles from '../hooks/useFetchUserBundles';
import useFetchBundleCreators from '../hooks/useFetchBundleCreators';
import useAuth from '../hooks/useAuth';
import BundleForm from './BundleForm';
import BundleList from './BundleList';
import BundleDetails from './BundleDetails';
import './InstructorDashboard.css';

const InstructorDashboard = () => {
    const { user, userRole, loading: authLoading } = useAuth();

    const { caseStudies, loading: caseStudiesLoading, error: caseStudiesError } = useFetchCaseStudies();
    const { mlKits, loading: mlKitsLoading, error: mlKitsError } = useFetchMlKits();
    const { createBundle, loading: bundleLoading, error: bundleError, success: createSuccess } = useCreateBundle();
    const { updateBundle, loading: updateLoading, error: updateError, success: updateSuccess } = useUpdateBundle();
    const { creators, loading: creatorsLoading, error: creatorsError } = useFetchBundleCreators();

    const [selectedCreatorId, setSelectedCreatorId] = useState(user?.uid);
    const { bundles, loading: bundlesLoading, error: bundlesError, reloadBundles } = useFetchUserBundles(selectedCreatorId, userRole);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showBundleDetails, setShowBundleDetails] = useState(false);

    // Keep track of form state to avoid losing it when toggling visibility
    const [formState, setFormState] = useState({
        bundleName: '',
        bundleDescription: '',
        selectedCaseStudies: [],
        selectedMlKits: [],
        period: 1,
        durationInDays: null,
        useDays: false,
        overridePrice: 0,
        useOverridePrice: false,
    });

    // Save a bundle or update it
    const handleBundleSave = (bundleData) => {
        const requestData = { ...bundleData, userId: user?.uid };
        if (selectedBundle) {
            updateBundle(selectedBundle.id, requestData);
        } else {
            createBundle(requestData);
        }
    };

    // Reload the list of bundles after creation/update
    const handleReload = () => {
        reloadBundles();
        setSelectedBundle(null);
    };

    const handleCreatorChange = (e) => {
        setSelectedCreatorId(e.target.value);
    };

    // Re-fetch bundles when the creator or user role changes
    useEffect(() => {
        if (userRole !== 'admin') {
            setSelectedCreatorId(user?.uid);
        }
        handleReload();
    }, [selectedCreatorId, userRole, user?.uid]);

    // Handle success of creation or update
    useEffect(() => {
        if ((createSuccess || updateSuccess) && !selectedBundle) {
            handleReload();
        }
    }, [createSuccess, updateSuccess, selectedBundle]);

    // Copy bundle code to clipboard
    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            alert("Bundle code copied to clipboard!");
        });
    };

    // Copy bundle purchase link to clipboard
    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            alert("Purchase link copied to clipboard!");
        });
    };

    // Open bundle details
    const handleViewDetails = (bundle) => {
        setSelectedBundle(bundle);
        setShowBundleDetails(true);
        setShowCreateForm(false);
    };

    // Open form for editing the selected bundle
    const handleEditBundle = (bundle) => {
        setSelectedBundle(bundle);
        setFormState({
            ...formState,
            bundleName: bundle.name,
            bundleDescription: bundle.description,
            selectedCaseStudies: bundle.caseStudies,
            selectedMlKits: bundle.mlKits,
            period: bundle.period || 1,
            durationInDays: bundle.durationInDays || null,
            useDays: !!bundle.durationInDays,
            overridePrice: bundle.bundlePrice || 0,
            useOverridePrice: bundle.bundlePrice !== 0,
        });
        setShowCreateForm(true);
        setShowBundleDetails(false);
    };

    // Open form for creating a new bundle
    const handleCreateNewBundle = () => {
        setSelectedBundle(null);
        setShowCreateForm(true);
        setShowBundleDetails(false);
    };

    // Handle canceling form or details view
    const handleCancel = () => {
        setShowCreateForm(false);
        setShowBundleDetails(false);
        setSelectedBundle(null);
    };

    if (caseStudiesLoading || mlKitsLoading || bundleLoading || bundlesLoading || authLoading || updateLoading || creatorsLoading) {
        return <div>Loading...</div>;
    }

    if (caseStudiesError || mlKitsError || bundleError || bundlesError || updateError || creatorsError) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="instructor-dashboard">
            {userRole === 'admin' && (
                <div className="bundle-creators-dropdown">
                    <label htmlFor="bundle-creators">Select Bundle Creator:</label>
                    <select id="bundle-creators" value={selectedCreatorId} onChange={handleCreatorChange}>
                        {creators.map((creator) => (
                            <option key={creator.id} value={creator.id}>
                                {creator.email}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <p className="info-text">
                {userRole === 'admin'
                    ? 'As an admin, you can manage bundles created by all users. Select a user from the dropdown to view their bundles.'
                    : 'Here you can create and manage your bundles. Select a bundle from the list to view details or edit it.'}
            </p>

            <div className="tabs">
                <button onClick={() => { setShowCreateForm(false); setShowBundleDetails(false); }}>View Bundles</button>
                <button onClick={handleCreateNewBundle}>Create Bundle</button>
            </div>

            {/* Show list of bundles when not viewing form or details */}
            {!showCreateForm && !showBundleDetails && (
                <BundleList
                    bundles={bundles}
                    onSelect={handleViewDetails}
                    onEdit={handleEditBundle}
                    onCopyCode={handleCopyCode}
                    onCopyLink={handleCopyLink}
                />
            )}

            {/* Show form for creating or editing bundle */}
            {showCreateForm && (
                <div>
                    <BundleForm
                        caseStudies={caseStudies}
                        mlKits={mlKits}
                        onSave={handleBundleSave}
                        selectedBundle={selectedBundle}
                        formState={formState}
                        setFormState={setFormState} // Pass form state handler
                        userId={user?.uid}
                        userRole={userRole}
                        onReload={handleReload}
                    />
                    <button onClick={handleCancel}>Cancel</button>
                </div>
            )}

            {/* Show bundle details */}
            {showBundleDetails && selectedBundle && (
                <BundleDetails
                    initialBundle={selectedBundle} // This must match what BundleDetails expects
                    onCopyLink={handleCopyLink}
                    onBack={handleCancel} // Return to bundle list
                />
            )}
        </div>
    );
};

export default InstructorDashboard;
