import { useState } from 'react';
import customAxios from '../customAxios';

const useUpdateBundle = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const updateBundle = async (bundleId, bundleData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        console.log('Bundle Data being sent to update:', bundleData); // Debugging

        try {
            await customAxios.put(`${process.env.REACT_APP_API_BASE_URL}/bundles/${bundleId}`, bundleData);
            console.log('Bundle updated successfully:', bundleId);
            setSuccess(true);
        } catch (err) {
            console.error('Error updating bundle:', err); // Debugging
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { updateBundle, loading, error, success };
};

export default useUpdateBundle;
