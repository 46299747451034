import React, { useState } from 'react';

const Modal = ({ show, onClose, message }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="rounded-3xl shadow-2xl bg-white p-8 text-center sm:p-12">
                <p className="text-lg text-gray-500">{message}</p>
                <button
                    className="mt-4 inline-block w-[200px] rounded-full bg-gray-600 py-2 text-sm font-bold text-white shadow-xl"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const Support = () => {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="mt-[90px] max-w-[1240px] mx-auto px-4 py-16">
            <h1 className="text-center text-4xl font-bold mb-4 text-[#25d366]">Support</h1>
            <p className="text-center text-lg text-gray-600 mb-12">
                We’re here to assist you with your queries and issues. While we strive to reply promptly, please allow up to 24 hours for responses, especially during weekends and holidays.
            </p>

            <div className='text-center'>
                <div className="bg-white rounded-lg p-6 shadow-md">
                    <h2 className='font-semibold text-[#25d366] text-xl'>Contact Us</h2>
                    <p className="mb-6">
                        📧 <a href="mailto:info@statleap.com" className="text-blue-500 underline">statleap@gmail.com</a> {/* Email link to open mail apps */}
                    </p>
                    <h3 className="text-xl font-semibold mb-2 text-[#25d366]">Support Hours</h3>
                    <p className="mb-2"><strong>Weekdays (including Saturdays):</strong></p>
                    <p className="ml-4 mb-4">Email Support: 9:30 am – 8:00 pm</p>
                    <p className="mb-2"><strong>Sundays & Public Holidays:</strong></p>
                    <p className="ml-4 mb-4">Email Support: 9:30 am – 8:00 pm</p>
                    <p className="font-semibold">For urgent matters, kindly mention “Urgent” in the subject line for prioritized assistance.</p>
                </div>
            </div>

            <Modal show={showModal} onClose={handleCloseModal} message="Form has been submitted and it will be resolved within 24 hours." />
        </div>
    );
};

export default Support;
