import React from "react";
import { motion } from "framer-motion";
import Benefits from "./Benefits"; // Import the Benefits component

const WhyStatLeap = () => {
    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.3 } }
    };

    return (
        <div className="py-14 px-4 max-w-[1240px] mt-20 md:mt-12 lg:mt-4 h-auto mx-auto flex flex-col justify-center">
            <div className="text-center">
                <p className="uppercase text-[#25d366] text-xs lg:text-md py-2">
                    Define the Power of Data Analytics
                </p>
                <h1 className="text-4xl lg:text-5xl text-[#25d366] font-extrabold">
                    Why Stat<span className="navbar-span text-black">Leap</span><span className="tm-symbol">&trade;</span>?
                </h1>
            </div>


            {/* Benefits Section - Inserted Between "Why Stat Leap" and Sign-Up/Login */}
            <Benefits /> {/* Integrated the Benefits component here */}

            <div className="flex flex-col justify-center">
                <motion.button
                    whileTap={{ scale: 0.9 }}
                    whileHover={{ scale: 1.1, backgroundColor: "#000", color: "#fff" }}
                    className="bg-[#25d366] w-[200px] rounded-lg text-lg my-6 mx-auto py-2"
                    type="submit"
                    onClick={() => window.location.href = "/login"} // Redirect to /login on click
                >
                    Sign Up
                </motion.button>
            </div>
        </div>
    );
};

export default WhyStatLeap;
