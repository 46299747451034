import React, { useState, useEffect } from 'react';
import customAxios from '../customAxios';
import useUpdateUserRole from '../hooks/useUpdateUserRole';
import useUpdateUserAuthorizations from '../hooks/useUpdateUserAuthorizations';
import { Timestamp } from 'firebase/firestore'; // Firestore Timestamp
import './UserDetail.css';

const UserDetail = ({ user }) => {
    const [role, setRole] = useState(user.role || 'user');
    const [authorizedMlKits, setAuthorizedMlKits] = useState({});
    const [authorizedCaseStudies, setAuthorizedCaseStudies] = useState({});
    const [availableMlKits, setAvailableMlKits] = useState([]);
    const [availableCaseStudies, setAvailableCaseStudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pendingRole, setPendingRole] = useState('');
    const [pendingChanges, setPendingChanges] = useState({ mlKits: {}, caseStudies: {} });
    const [expirationDate, setExpirationDate] = useState(new Date());

    const { updateUserRole, loading: roleLoading, error: roleError } = useUpdateUserRole();
    const { updateUserAuthorizations, loading: authLoading, error: authError } = useUpdateUserAuthorizations();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${user.id}`);
                const userDetails = userResponse.data;
                setRole(userDetails.role || 'user');

                const userAuthResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/userAuthorizations/${user.id}`);
                const { mlKits = {}, caseStudies = {} } = userAuthResponse.data;
                setAuthorizedMlKits(mlKits);
                setAuthorizedCaseStudies(caseStudies);

                const [mlKitsResponse, caseStudiesResponse] = await Promise.all([
                    customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/mlKits`),
                    customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/case-studies`)
                ]);
                setAvailableMlKits(mlKitsResponse.data);
                setAvailableCaseStudies(caseStudiesResponse.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [user.id]);

    // Function to handle changes in authorizations
    const handleAuthorizationChange = (type, id, authorized) => {
        setPendingChanges((prev) => {
            const updatedAuth = { ...prev[type] };

            if (authorized) {
                const expirationTimestamp = Timestamp.fromDate(expirationDate);
                updatedAuth[id] = expirationTimestamp;
            } else {
                delete updatedAuth[id];
            }

            return { ...prev, [type]: updatedAuth };
        });
    };

    // Merge unchanged and pending changes, then send all authorizations to the backend
    const handleSubmitChanges = () => {
        const mergedMlKits = { ...authorizedMlKits }; // Start with old mlKits (including their original expiration dates)
        const mergedCaseStudies = { ...authorizedCaseStudies }; // Start with old caseStudies (including their original expiration dates)

        // Update mlKits with pending changes, applying new expiration dates only to modified ones
        Object.keys(pendingChanges.mlKits).forEach(mlKitId => {
            if (pendingChanges.mlKits[mlKitId]) {
                mergedMlKits[mlKitId] = Timestamp.fromDate(expirationDate); // Apply new expiration date to changed items
            } else {
                delete mergedMlKits[mlKitId]; // Remove unchecked items
            }
        });

        // Update caseStudies with pending changes, applying new expiration dates only to modified ones
        Object.keys(pendingChanges.caseStudies).forEach(caseStudyId => {
            if (pendingChanges.caseStudies[caseStudyId]) {
                mergedCaseStudies[caseStudyId] = Timestamp.fromDate(expirationDate); // Apply new expiration date to changed items
            } else {
                delete mergedCaseStudies[caseStudyId]; // Remove unchecked items
            }
        });

        const requestBody = {
            mlKits: mergedMlKits, // Send all authorized mlKits with their respective expiration dates
            caseStudies: mergedCaseStudies, // Send all authorized caseStudies with their respective expiration dates
        };

        updateUserAuthorizations(user.id, requestBody, expirationDate.toISOString());
    };

    const convertFirestoreTimestampToDate = (timestamp) => {
        const seconds = timestamp.seconds || timestamp._seconds;
        return new Date(seconds * 1000).toLocaleDateString();
    };

    if (loading || roleLoading || authLoading) return <div>Loading...</div>;
    if (error || roleError || authError) return <div>Error fetching data: {error?.message || roleError?.message || authError?.message}</div>;

    return (
        <div className="user-detail">
            <h3>User Detail</h3>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Name:</strong> {user.displayName}</p>
            <label><strong>Role:</strong>
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="user">User</option>
                    <option value="instructor">Instructor</option>
                    <option value="admin">Admin</option>
                </select>
            </label>

            <label>
                <strong>Authorize until:</strong>
                <input
                    type="date"
                    value={expirationDate.toISOString().split('T')[0]}
                    onChange={(e) => setExpirationDate(new Date(e.target.value))}
                />
            </label>

            <h4>Authorize/Deauthorize ML Kits</h4>
            {availableMlKits.map(mlKit => (
                <div key={mlKit.id}>
                    <input
                        type="checkbox"
                        checked={!!pendingChanges.mlKits[mlKit.id] || !!authorizedMlKits[mlKit.id]}
                        onChange={(e) => handleAuthorizationChange('mlKits', mlKit.id, e.target.checked)}
                    />
                    {mlKit.toolName}
                    {authorizedMlKits[mlKit.id] && (
                        <span> - Authorized until: {convertFirestoreTimestampToDate(authorizedMlKits[mlKit.id])}</span>
                    )}
                </div>
            ))}

            <h4>Authorize/Deauthorize Case Studies</h4>
            {availableCaseStudies.map(caseStudy => (
                <div key={caseStudy.id}>
                    <input
                        type="checkbox"
                        checked={!!pendingChanges.caseStudies[caseStudy.id] || !!authorizedCaseStudies[caseStudy.id]}
                        onChange={(e) => handleAuthorizationChange('caseStudies', caseStudy.id, e.target.checked)}
                    />
                    {caseStudy.name}
                    {authorizedCaseStudies[caseStudy.id] && (
                        <span> - Authorized until: {convertFirestoreTimestampToDate(authorizedCaseStudies[caseStudy.id])}</span>
                    )}
                </div>
            ))}

            <button onClick={handleSubmitChanges}>Submit Changes</button>
        </div>
    );
};

export default UserDetail;
