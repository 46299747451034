import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import WhyStatLeap from "./WhyStatLeap";
import ThreeStepDiagram from "./ThreeStepDiagram";  // Import the new component
import Footer from "./Footer";
import { FaChevronDown } from 'react-icons/fa';  // Import arrow icon
import './HeroPage.css';

const HeroPage = () => {
    const [isAtBottom, setIsAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.innerHeight + window.scrollY;
            const pageHeight = document.documentElement.scrollHeight; // Correct height of the entire page

            // Hide the scroll indicator only when the user is near the end of the page (e.g., within 100px of the bottom)
            if (scrollPosition >= pageHeight - 100) {
                setIsAtBottom(true);
            } else {
                setIsAtBottom(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="hero-page-container">
            {/* Hero Section */}
            <Hero />

            {/* Scroll Down Indicator */}
            <div className={`scroll-indicator ${isAtBottom ? 'hidden' : ''}`}>
                <p>Scroll Down</p>
                <FaChevronDown className="scroll-arrow" />
            </div>

            {/* Why StatLeap Section */}
            <div className="why-stat-leap-section">
                <WhyStatLeap />
            </div>

            {/* Three-Step Diagram Section */}
            <div className="three-step-section">
                <ThreeStepDiagram />
            </div>

            {/* ML Kits Promo Section */}
            <div className="mlkits-promo-section">
                <h2 className="mlkits-title">Unlock the Power of Machine Learning</h2>
                <p className="mlkits-description">
                    Discover our collection of powerful, no-code machine learning kits designed for everyone, from beginners to advanced users.
                </p>
                <a href="/ml-tools" className="mlkits-screenshot-link">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/statleaptest.appspot.com/o/mlkits%2Fmlkits-promo.png?alt=media&token=3b40b780-df04-492f-a14c-5968cefd08f8"
                        alt="ML Kits Promo"
                        className="mlkits-screenshot"
                    />
                </a>
                <div className="mlkits-cta-container">
                    <a href="/ml-tools" className="mlkits-cta-button">
                        Sign Up to Explore ML Kits
                    </a>
                </div>
            </div>

            {/* Footer Section */}
            <Footer />
        </div>
    );
};

export default HeroPage;
