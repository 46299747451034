import React from "react";
import { Link } from "react-router-dom";
import './Footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-header">
                    <h1 className="footer-logo">
                        Stat<span className="footer-logo-highlight">Leap</span><span className="tm-symbol">&trade;</span>
                    </h1>
                </div>
                <div className="footer-content">
                    <div className="footer-columns">
                        <div className="footer-column">
                            <h6 className="footer-heading">Quick Links</h6>
                            <ul>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/eula">EULA</Link></li>
                                <li><Link to="/support">Contact</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
