import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import customAxios from "../customAxios";
import useAuth from "../hooks/useAuth";
import useFetchUser from "../hooks/useFetchUser";
import { stripePromise } from "../stripe";
import useRedeemFreeBundle from "../hooks/useRedeemFreeBundle";
import { motion } from "framer-motion";
import "./BundlePurchase.css";
import './Plans.css';  // Importing the CSS file for styling

const subscriptionPlans = [
    {
        name: "Essentials Plan",
        price: "$35",
        duration: "3 months",
        benefits: [
            "Get started with core access to datasets and limited case studies, along with standard customer support.",
        ],
        purchaseLink: "https://statleap.org/purchase-bundle?code=8OML78JY"
    },
    {
        name: "Professional Plan",
        price: "$50",
        duration: "6 months",
        cardSize: "recommended-plan",
        cardColor: "highlight-plan",
        recommended: true, // Marked as the recommended plan
        benefits: [
            "Unlock advanced datasets, more case studies, and receive priority customer support for greater depth and engagement."
        ],
        purchaseLink: "https://statleap.org/purchase-bundle?code=1WUC6ST8"
    },
    {
        name: "Elite Plan",
        price: "$80",
        duration: "12 months",
        benefits: [
            "Enjoy complete access to all datasets, exclusive case studies, and top-tier, dedicated customer support for a full year of powerful analytics."
        ],
        purchaseLink: "https://statleap.org/purchase-bundle?code=JHXBF1FV"
    }
];

const BundlePurchase = () => {
    const { user: authUser, loading: authLoading } = useAuth();
    const { user: userDetails, loading: userLoading, error: userError } = useFetchUser(authUser?.uid);
    const { redeemFreeBundle, loading: redeeming, error: redeemError } = useRedeemFreeBundle();

    const [bundleCode, setBundleCode] = useState("");
    const [bundleDetails, setBundleDetails] = useState(null);
    const [authorName, setAuthorName] = useState("");
    const [loadingBundle, setLoadingBundle] = useState(false);
    const [error, setError] = useState(null);
    const [alreadyPurchased, setAlreadyPurchased] = useState(false);
    const [showPlans, setShowPlans] = useState(false); // To toggle the Plans section visibility

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const codeFromURL = queryParams.get("code");
        if (codeFromURL && !authLoading && authUser) {
            console.log("Found bundle code in URL:", codeFromURL);
            setBundleCode(codeFromURL);
            handleBundleCodeSubmit(codeFromURL);
        }
    }, [location.search, authLoading, authUser]);

    const extractCodeFromUrl = (url) => {
        const urlParams = new URLSearchParams(new URL(url).search);
        return urlParams.get("code");
    };

    const handleBundleCodeSubmit = async (code) => {
        console.log("Fetching bundle details for code:", code || bundleCode);
        setLoadingBundle(true);
        setError(null);
        setAlreadyPurchased(false);

        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles/code/${code || bundleCode}`);
            const bundleData = response.data;
            console.log("Bundle details fetched:", bundleData);
            setBundleDetails(bundleData);

            const authorResponse = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${bundleData.userId}`);
            setAuthorName(authorResponse.data.displayName || "Unknown Author");

            const purchasedBundles = userDetails?.purchasedBundles || [];
            const isAlreadyPurchased = purchasedBundles.some(purchase => purchase.bundleId === bundleData.id);
            setAlreadyPurchased(isAlreadyPurchased);
            console.log("Already purchased:", isAlreadyPurchased);

        } catch (err) {
            console.error("Error fetching bundle details:", err);
            setError("Invalid bundle code. Please try again.");
            setBundleDetails(null);
        } finally {
            setLoadingBundle(false);
        }
    };

    const handlePurchaseOrRedeem = async () => {
        if (!authUser || authLoading) {
            setError("You need to be logged in to redeem or purchase a bundle.");
            console.log("User not logged in or still loading.");
            return;
        }

        if (alreadyPurchased) {
            setError("You have already redeemed or purchased this bundle.");
            console.log("User has already purchased this bundle.");
            return;
        }

        setLoadingBundle(true);
        console.log("Handling purchase or redeem for bundle:", bundleDetails);

        try {
            if (bundleDetails.bundlePrice === 0) {
                // Handle free bundle redemption
                await redeemFreeBundle(bundleDetails.id, authUser.uid);
                navigate("/success", { state: { bundleName: bundleDetails.name, purchaseType: "redeem" } });
            } else {
                // Handle paid bundle via Stripe
                const response = await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-bundle`, {
                    bundleId: bundleDetails.id,
                    userId: authUser.uid,
                });

                const stripe = await stripePromise;
                await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
            }
        } catch (err) {
            console.error("Error processing purchase or redeem request:", err);
            setError("There was an error processing your request. Please try again.");
        } finally {
            setLoadingBundle(false);
        }
    };

    if (authLoading || userLoading) return <div>Loading...</div>;
    if (userError) return <div>Error loading user data: {userError.message}</div>;

    return (
        <div className="bundle-purchase-container max-w-[1240px] mx-auto mt-[90px] px-4 py-16">
            <h1 className="text-center text-4xl font-bold mb-8 text-[#25d366]">Purchase or Redeem Your Bundle</h1>
            <div className="bundle-code-input-section text-center">
                <h2 className="text-2xl font-bold text-[#25d366] mb-4">Enter Your Bundle Code</h2>
                <p className="text-center text-lg text-gray-600 mb-4">
                    Enter the Instructor's Bundle Code provided by your instructor to redeem or purchase a discounted bundle. This will give you access to the ML Kits and resources included in the bundle.
                </p>
                <input
                    type="text"
                    placeholder="Enter bundle code"
                    className="bundle-code-input border-2 border-gray-300 p-2 rounded-md mb-4"
                    value={bundleCode}
                    onChange={(e) => setBundleCode(e.target.value)}
                />
                <button
                    onClick={() => handleBundleCodeSubmit()}
                    className="submit-bundle-code bg-[#25d366] text-white px-4 py-2 rounded-md font-medium"
                    disabled={loadingBundle}
                >
                    {loadingBundle ? "Loading..." : "Submit"}
                </button>

                {error && <p className="text-red-500 mt-4">{error}</p>}
            </div>

            {bundleDetails && (
                <div className="bundle-details mt-10 text-center">
                    <h3 className="text-xl font-bold">{bundleDetails.name}</h3>
                    <p className="text-gray-600">{bundleDetails.description}</p>
                    <p className="text-lg font-bold text-[#25d366]">
                        Price: {bundleDetails.bundlePrice === 0 ? 'Free' : `$${bundleDetails.bundlePrice.toFixed(2)}`}
                    </p>
                    <button
                        onClick={handlePurchaseOrRedeem}
                        className="purchase-bundle bg-black text-white px-6 py-2 rounded-md font-medium mt-6"
                        disabled={loadingBundle || alreadyPurchased}
                    >
                        {loadingBundle ? "Processing..." : bundleDetails.bundlePrice === 0 ? "Redeem" : `Buy for $${bundleDetails.bundlePrice.toFixed(2)}`}
                    </button>
                    {alreadyPurchased && <p className="text-green-500 mt-4">You have already redeemed or purchased this bundle.</p>}
                </div>
            )}

            {/* Collapsible Plans Section */}
            <div className="plans-section mt-10">
                <button
                    className="toggle-plans bg-[#25d366] text-white px-4 py-2 rounded-md font-medium"
                    onClick={() => setShowPlans(!showPlans)}
                >
                    {showPlans ? "Hide Subscription Plans" : "Show Subscription Plans"}
                </button>

                {showPlans && (
                    <div className="plans-container mt-8">
                        <h1 className="plans-title">Choose Your Subscription Plan</h1>
                        <p className="plans-subtitle">
                            Select the subscription plan that best suits your needs.<br />
                            Get access to our tools for 3, 6, or 12 months. <br />
                            <strong>Note: If your instructor provides an Instructor's Bundle Code, please use that option instead of selecting a subscription here to receive the best price.</strong>
                        </p>
                        <div className="plans-grid">
                            {subscriptionPlans.map((plan, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.2 }}
                                    className={`plan-card ${plan.cardColor} ${plan.cardSize}`}
                                >
                                    {plan.recommended && (
                                        <div className="recommended-badge">
                                            <span className="badge-text">Recommended</span>
                                        </div>
                                    )}
                                    <h1 className="plan-name">{plan.name}</h1>
                                    <h2 className="plan-price">{plan.price}</h2>
                                    <div className="plan-duration">
                                        <p>({plan.duration})</p>
                                    </div>
                                    <ul className="plan-benefits">
                                        {plan.benefits.map((benefit, i) => (
                                            <li key={i} className="benefit-item">{benefit}</li>
                                        ))}
                                    </ul>
                                    <button
                                        className="purchase-button"
                                        onClick={() => {
                                            const code = extractCodeFromUrl(plan.purchaseLink);
                                            setBundleCode(code);  // Set the code in the form
                                            handleBundleCodeSubmit(code);  // Fetch bundle details for the code
                                            setShowPlans(false);  // Optionally close the Plans section
                                        }}
                                    >
                                        Purchase Now
                                    </button>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BundlePurchase;
