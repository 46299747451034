import React from 'react';

const EULA = () => {
    return (
        <div className="mt-[90px] max-w-[1240px] mx-auto px-4 py-16">
            <h1 className="text-center text-4xl font-bold mb-4 text-[#25d366]">End User License Agreement</h1>

            <p className="text-lg text-gray-600 mb-12">This Agreement governs access and use of StatLeap products and services.</p>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">1. Definitions</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li><strong>Abuse:</strong> Any unauthorized use of the product, such as sharing passwords or introducing malicious code.</li>
                <li><strong>Agreement:</strong> This document, including its annexures.</li>
                <li><strong>Customization:</strong> Any changes or modifications requested by the End User.</li>
                <li><strong>End User Data:</strong> Data generated or uploaded by the End User through use of the product.</li>
                <li><strong>Product:</strong> StatLeap�s proprietary platform and tools.</li>
                <li><strong>Proprietary Information:</strong> Includes the product, methodologies, and any intellectual property.</li>
                <li><strong>Services:</strong> The tools and features provided by StatLeap.</li>
                <li><strong>Term:</strong> Duration of the Agreement.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">2. Grant of License & Intellectual Property</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>StatLeap grants a revocable, non-exclusive, non-transferable license for the End User to use the product during the term.</li>
                <li>All intellectual property rights to the product remain with StatLeap.</li>
                <li>End User retains ownership of any uploaded data, while StatLeap retains rights to usage data for performance improvements.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">3. Usage Restrictions</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>The End User cannot provide third-party access, reverse-engineer the product, or resell the license.</li>
                <li>StatLeap retains the right to monitor the product�s use to ensure compliance.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">4. Fees & Payment Terms</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>Fees must be paid by the End User as agreed. License activation occurs after payment.</li>
                <li>Late payments may incur interest.</li>
                <li>Taxes and other applicable charges are borne by the End User.</li>
                <li>Refunds are available only within the first 30 days and subject to cancellation fees.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">5. Security & Data Protection</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>StatLeap uses reasonable security measures but is not liable for external breaches unless due to gross negligence.</li>
                <li>The End User is responsible for ensuring that their data complies with applicable regulations.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">6. Confidentiality</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>Both parties agree to keep each other�s proprietary information confidential.</li>
                <li>End User data is treated as confidential, and StatLeap will not disclose it except as required by law.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">7. Termination & Refund Policy</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>Either party may terminate the Agreement with 30 days' written notice.</li>
                <li>Refunds are available only within the first month, subject to a 1/3rd cancellation fee.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">8. Limitation of Liability</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>StatLeap's liability is limited to the fees paid in the preceding six months.</li>
                <li>StatLeap is not liable for indirect damages, such as loss of profits or business interruptions.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">9. Warranties</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>StatLeap does not guarantee uninterrupted service or that the product will meet the End User�s specific requirements.</li>
                <li>StatLeap disclaims any implied warranties, including merchantability or fitness for a particular purpose.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">10. Force Majeure</h2>
            <p className="text-lg text-gray-600 mb-8">
                Neither party will be liable for failure to perform obligations due to events beyond their control, such as natural disasters or pandemics.
            </p>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">11. Governing Law & Dispute Resolution</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>The Agreement is governed by the laws of the Republic of India.</li>
                <li>Any disputes will be resolved by arbitration in Bangalore, in accordance with Indian Arbitration Law.</li>
            </ul>

            <h2 className="font-semibold text-xl text-[#25d366] mb-4">12. General Provisions</h2>
            <ul className="list-disc ml-6 text-lg text-gray-600 mb-8">
                <li>Any changes to the Agreement must be in writing and signed by both parties.</li>
                <li>If any provision is found invalid, the remaining provisions will remain in effect.</li>
                <li>Neither party may assign rights or obligations without the other�s consent.</li>
            </ul>
        </div>
    );
};

export default EULA;
