import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import useAuth from '../hooks/useAuth';
import NavbarLinks from './NavbarLinks';
import MobileNavbar from './MobileNavbar';
import './Navbar.css';
import { signOut } from '../auth';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const { user, loading, userRole } = useAuth();
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const closeSubmenus = () => {
    setNav(false);
  };

  const handleLogout = async () => {
    await signOut();
    closeSubmenus();
    navigate('/', { replace: true });
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-title">
                  <Link to="/" onClick={closeSubmenus}>Stat<span className="navbar-span">Leap</span><span className="tm-symbol">&trade;</span></Link>
        </h1>
        {loading ? (
          <div className="navbar-loading">Loading...</div>
        ) : (
          <NavbarLinks user={user} userRole={userRole} handleLogout={handleLogout} closeSubmenus={closeSubmenus} />
        )}
        <div onClick={handleNav} className="navbar-toggle">
          {!nav ? <AiOutlineMenu size={24} color="#25d366" /> : <AiOutlineClose size={24} color="#25d366" />}
        </div>
        <MobileNavbar nav={nav} handleNav={handleNav} user={user} userRole={userRole} handleLogout={handleLogout} closeSubmenus={closeSubmenus} />
      </div>
    </nav>
  );
};

export default Navbar;
