import React, { useEffect, useState } from 'react';
import './BundleDetails.css';
import { exportToCsv } from '../utils/exportToCsv'; // CSV export utility
import customAxios from '../customAxios'; // Use customAxios for API requests
import useAuth from '../hooks/useAuth'; // Hook to get authentication info

const BundleDetails = ({ initialBundle, onCopyLink, onBack }) => {
    const { user, userRole, loading: authLoading } = useAuth(); // Fetch the logged-in user and role
    const [currentAccessUsers, setCurrentAccessUsers] = useState([]); // Users with current access
    const [expiredAccessUsers, setExpiredAccessUsers] = useState([]); // Users with expired access
    const [trialDetails, setTrialDetails] = useState(null); // State for fetching free trial details
    const [currentBundle, setCurrentBundle] = useState(initialBundle || null); // Initialize with initialBundle or null
    const [originalBundle, setOriginalBundle] = useState(null); // Store the original bundle when it's a free trial
    const [csvFile, setCsvFile] = useState(null); // State for uploaded CSV file
    const [inviteEmail, setInviteEmail] = useState(''); // State for single email invite
    const [invitedUsers, setInvitedUsers] = useState([]); // Invited users

    useEffect(() => {
        if (currentBundle && currentBundle.id) {
            fetchInvitedUsers(currentBundle.id);
            if (currentBundle.freeTrialBundleId) {
                fetchTrialDetails(currentBundle.freeTrialBundleId); // Fetch trial details if the bundle has a free trial
            }

            // Fetch original bundle if the current bundle is a free trial
            if (currentBundle.freeTrial && currentBundle.originalBundleId) {
                fetchOriginalBundle(currentBundle.originalBundleId);
            }
        }
    }, [currentBundle]);

    // Fetch the free trial details if freeTrialBundleId is present
    const fetchTrialDetails = async (trialBundleId) => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles/${trialBundleId}`);
            setTrialDetails(response.data); // Set the fetched trial details
        } catch (err) {
            console.error('Error fetching trial details:', err);
        }
    };

    // Fetch the original bundle details if the current bundle is a free trial
    const fetchOriginalBundle = async (originalBundleId) => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles/${originalBundleId}`);
            setOriginalBundle(response.data); // Set the original bundle details
        } catch (err) {
            console.error('Error fetching original bundle details:', err);
        }
    };

    // Fetch invited users
    const fetchInvitedUsers = async (bundleId) => {
        try {
            const response = await customAxios.get(`${process.env.REACT_APP_API_BASE_URL}/bundles/${bundleId}/invited-users`);
            setInvitedUsers(response.data);
        } catch (error) {
            console.error('Error fetching invited users:', error);
        }
    };

    // Handle creating a trial version
    const handleCreateTrial = async () => {
        const durationInDays = prompt('Enter trial duration in days:');
        if (durationInDays) {
            try {
                const response = await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/api/bundles/${currentBundle.id}/trial`, {
                    userId: user.uid,
                    durationInDays,
                });
                setTrialDetails(response.data.trialBundle);
                alert('Free trial created successfully.');
            } catch (error) {
                console.error('Error creating trial:', error);
                alert('Failed to create trial.');
            }
        }
    };

    // Handle exporting current and expired access users to CSV
    const handleExportCsv = () => {
        const csvData = [
            ...currentAccessUsers.map(user => ({
                email: user.email,
                displayName: user.displayName,
                expirationDate: user.expirationDate.toISOString(),
                status: 'Current Access',
            })),
            ...expiredAccessUsers.map(user => ({
                email: user.email,
                displayName: user.displayName,
                expirationDate: user.expirationDate.toISOString(),
                status: 'Expired Access',
            })),
        ];
        exportToCsv(`${currentBundle.name}_purchasers.csv`, csvData);
    };

    // Handle single email invite
    const handleSendInvite = async () => {
        if (!inviteEmail) {
            alert('Please enter an email address.');
            return;
        }

        try {
            await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/send-invite`, {
                email: inviteEmail,
                bundleId: currentBundle.id,
                userId: user.uid,
            });
            alert('Invite sent successfully.');
            setInviteEmail(''); // Clear input
            fetchInvitedUsers(currentBundle.id); // Refresh invited users
        } catch (error) {
            console.error('Error sending invite:', error);
            alert('Failed to send invite.');
        }
    };

    // Handle CSV file upload and mass invite
    const handleMassInvite = async () => {
        if (!csvFile) {
            alert('Please upload a CSV file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', csvFile);
        formData.append('bundleId', currentBundle.id);
        formData.append('userId', user.uid);

        try {
            await customAxios.post(`${process.env.REACT_APP_API_BASE_URL}/mass-invite`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            alert('Invites sent successfully.');
            setCsvFile(null); // Clear file input
            fetchInvitedUsers(currentBundle.id); // Refresh invited users
        } catch (error) {
            console.error('Error sending mass invites:', error);
            alert('Failed to send invites.');
        }
    };

    if (authLoading) return <div>Loading data...</div>;

    return (
        <div className="bundle-details">
            <h2>Bundle Details</h2>
            <p>
                <strong>Bundle Name:</strong> {currentBundle.name}
            </p>
            <p>
                <strong>Description:</strong> {currentBundle.description}
            </p>
            <p>
                <strong>Price:</strong> {currentBundle.isFree ? 'Free' : `$${currentBundle.bundlePrice.toFixed(2)}`}
            </p>
            <p>
                <strong>Purchase Code:</strong> {currentBundle.code}
            </p>
            <button onClick={() => onCopyLink(currentBundle.code)} className="copy-button">
                Copy Purchase Link
            </button>

            <h3>Invite Users</h3>
            <div className="single-invite">
                <h3>Invite Single User</h3>
                <input
                    type="email"
                    placeholder="Enter email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                />
                <button onClick={handleSendInvite}>Send Invite</button>
            </div>

            <div className="csv-invites">
                <h3>Mass Invite via CSV</h3>
                <input type="file" accept=".csv" onChange={(e) => setCsvFile(e.target.files[0])} />
                <button onClick={handleMassInvite}>Upload and Invite</button>
                <div className="csv-instructions">
                    <h4>CSV Format Instructions</h4>
                    <pre>
                        email
                        user1@example.com
                        user2@example.com
                    </pre>
                </div>
            </div>

            <h3>Invited Users</h3>
            <ul>
                {invitedUsers.length > 0 ? (
                    invitedUsers.map((user, index) => (
                        <li key={index}>
                            {user.email} - Invited by {user.invitedBy}
                        </li>
                    ))
                ) : (
                    <li>No users have been invited to this bundle.</li>
                )}
            </ul>

            <button onClick={handleExportCsv} className="export-button">
                Export Purchasers as CSV
            </button>

            {/* Free Trial Section */}
            {currentBundle.freeTrial ? (
                <>
                    <p>This bundle is a free trial.</p>
                    <button
                        onClick={() => setCurrentBundle(originalBundle || initialBundle)} // Switch back to original bundle
                        className="back-button"
                        disabled={!originalBundle && !initialBundle}
                    >
                        View Original Bundle
                    </button>
                </>
            ) : (
                userRole === 'admin' && (
                    <div>
                        {trialDetails ? (
                            <>
                                <h3>Free Trial Version</h3>
                                <p>
                                    <strong>Trial Code:</strong> {trialDetails.code}
                                </p>
                                <button onClick={() => onCopyLink(trialDetails.code)} className="copy-button">
                                    Copy Trial Link
                                </button>
                                <button
                                    onClick={() => setCurrentBundle(trialDetails)} // Switch to trial bundle details
                                    className="view-trial-button"
                                >
                                    View Free Trial Details
                                </button>
                            </>
                        ) : (
                            <button onClick={handleCreateTrial} className="trial-button">
                                Create Free Trial Version
                            </button>
                        )}
                    </div>
                )
            )}

            <button onClick={onBack} className="back-button">
                Back to Bundle List
            </button>
        </div>
    );
};

export default BundleDetails;
