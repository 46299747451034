import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./BundleSuccess.css"; // You can add specific CSS styles for the success page here

const BundleSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extracting any relevant data passed during navigation (e.g., bundle details)
    const { state } = location;
    const bundleName = state?.bundleName || "your bundle";
    const purchaseType = state?.purchaseType || "Redeem";

    useEffect(() => {
        // Redirect back to the user's profile or homepage after a delay
        const timer = setTimeout(() => {
            navigate("/profile");
        }, 5000); // Redirects after 10 seconds

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, [navigate]);

    return (
        <div className="bundle-success-container max-w-[1240px] mx-auto mt-[90px] px-4 py-16 text-center">
            <h1 className="text-4xl font-bold text-[#25d366] mb-4">Success!</h1>
            <p className="text-xl text-gray-600 mb-8">
                {purchaseType === "redeem"
                    ? `You have successfully redeemed ${bundleName}.`
                    : `Your purchase of ${bundleName} was successful.`}
            </p>
            <p className="text-lg text-gray-500">You will be redirected to your profile shortly.</p>
            <div className="loading-spinner mt-8">
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-[#25d366]" role="status"></div>
            </div>
        </div>
    );
};

export default BundleSuccess;

